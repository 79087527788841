<template>
  <b-sidebar id="add-edit-type-sidebar" :visible="isAddEditTypeSidebarActive" bg-variant="white" width="50%" shadow
    no-header right @hidden="resetForm" @change="(val) => $emit('update:is-add-edit-type-sidebar-active', val)">
    <template #default="{ hide }">
      <b-modal id="modal-xl-edit" hide-footer size="xl" centered title="Gallery">
        <div class="modal_content">
          <b-row>
            <b-col cols="12" md="6" class="
                d-flex
                align-items-center
                justify-content-start
                mb-1 mb-md-0
              ">
              <b-button variant="primary" @click="preImage">pre</b-button>
              <b-button style="margin-left: 10px" @click="nextImage()" variant="primary">next</b-button>
            </b-col>
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-start">
                <b-form-select style="width: 100px;" @change="changeOptionImageResize" v-model="selectImageType" class="mr-2"  :options="optionImageType"></b-form-select>
                <b-form-input v-model="searchQuery" placeholder="Search..." />
              </div>
            </b-col>
          </b-row>
          <b-tabs style="height: 430px; overflow: scroll">
            <b-tab active @click="recent_image_add_in_gallery">
              <template #title>
                <span>Recently Uploaded </span>
              </template>
              <div :key="sidebarkey">
                <b-row class="match-height">
                  <b-col md="1" lg="1" style="padding: 8px; position: relative" v-for="galleryimg in getImages()"
                    :key="galleryimg.img_id" v-lazy-container="{
                        selector: 'img',
                        error:
                          'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                        loading:
                          'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                      }">
                     <div v-if="galleryimg.resize && galleryimg.resize[selectImageType]" @click="backgroundImageSave(galleryimg.resize[selectImageType].img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.resize[selectImageType].img" alt="gallery" />
                    </div>
                    <div v-else @click="backgroundImageSave(galleryimg.gallery_img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.gallery_img" alt="gallery" />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
            <b-tab @click="recent_image_used_in_gallery">
              <template #title>
                <span>Recently Used</span>
              </template>

              <div :key="sidebarkey">
                <b-row class="match-height">
                  <b-col md="1" lg="1" style="padding: 10px; position: relative" v-for="galleryimg in getImages()"
                    :key="galleryimg.img_id" v-lazy-container="{
                        selector: 'img',
                        error:
                          'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                        loading:
                          'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                      }">
                    <div v-if="galleryimg.resize && galleryimg.resize[selectImageType]" @click="backgroundImageSave(galleryimg.resize[selectImageType].img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.resize[selectImageType].img" alt="gallery" />
                    </div>
                    <div v-else @click="backgroundImageSave(galleryimg.gallery_img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.gallery_img" alt="gallery" />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
      <b-modal id="modal-Arr-checkBox-edit" hide-footer size="xl" centered title="Gallery">
        <div class="modal_content">
          <b-row>
            <b-col cols="12" md="6" class="
                d-flex
                align-items-center
                justify-content-start
                mb-1 mb-md-0
              ">
              <b-button variant="primary" @click="preImage">pre</b-button>
              <b-button style="margin-left: 10px" @click="nextImage()" variant="primary">next</b-button>
            </b-col>
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-start">
                <b-form-select style="width: 100px;" @change="changeOptionImageResize" v-model="selectImageType" class="mr-2"  :options="optionImageType"></b-form-select>
                <b-form-input v-model="searchQuery" placeholder="Search..." />
              </div>
            </b-col>
          </b-row>

          <b-tabs style="height: 430px; overflow: scroll">
            <b-tab active @click="recent_image_add_in_gallery">
              <template #title>
                <span>Recently Uploaded </span>
              </template>

              <div :key="sidebarkey">
                <b-row class="match-height">
                  <b-col md="1" lg="1" style="padding: 8px; position: relative" v-for="galleryimg in getImages()"
                    :key="galleryimg.img_id" v-lazy-container="{
                        selector: 'img',
                        error:
                          'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                        loading:
                          'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                      }">
                    <div v-if="galleryimg.resize && galleryimg.resize[selectImageType]" @click="ArrayCheckboxIndex(galleryimg.resize[selectImageType].img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.resize[selectImageType].img" alt="gallery" />
                    </div>
                    <div v-else @click="ArrayCheckboxIndex(galleryimg.gallery_img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.gallery_img" alt="gallery" />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
            <b-tab @click="recent_image_used_in_gallery">
              <template #title>
                <span>Recently Used</span>
              </template>

              <div :key="sidebarkey">
                <b-row class="match-height">
                  <b-col md="1" lg="1" style="padding: 10px; position: relative" v-for="galleryimg in getImages()"
                    :key="galleryimg.img_id" v-lazy-container="{
                        selector: 'img',
                        error:
                          'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                        loading:
                          'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                      }">
                     <div v-if="galleryimg.resize && galleryimg.resize[selectImageType]" @click="ArrayCheckboxIndex(galleryimg.resize[selectImageType].img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.resize[selectImageType].img" alt="gallery" />
                    </div>
                    <div v-else @click="ArrayCheckboxIndex(galleryimg.gallery_img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.gallery_img" alt="gallery" />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
      <b-modal id="cl-image-edit" hide-footer size="xl" centered title="Gallery">
        <div class="modal_content">
          <b-row>
            <b-col cols="12" md="6" class="
                d-flex
                align-items-center
                justify-content-start
                mb-1 mb-md-0
              ">
              <b-button variant="primary" @click="preImage">pre</b-button>
              <b-button style="margin-left: 10px" @click="nextImage()" variant="primary">next</b-button>
            </b-col>
            <b-col cols="12" md="6">
              <div class="d-flex align-items-center justify-content-start">
                <b-form-select style="width: 100px;" @change="changeOptionImageResize" v-model="selectImageType" class="mr-2"  :options="optionImageType"></b-form-select>
                <b-form-input v-model="searchQuery" placeholder="Search..." />
              </div>
            </b-col>
          </b-row>

          <b-tabs style="height: 430px; overflow: scroll">
            <b-tab active @click="recent_image_add_in_gallery">
              <template #title>
                <span>Recently Uploaded </span>
              </template>

              <div :key="sidebarkey">
                <b-row class="match-height">
                  <b-col md="1" lg="1" style="padding: 8px; position: relative" v-for="galleryimg in getImages()"
                    :key="galleryimg.img_id" v-lazy-container="{
                        selector: 'img',
                        error:
                          'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                        loading:
                          'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                      }">
                  <div v-if="galleryimg.resize && galleryimg.resize[selectImageType]" @click="ArrayIndexObject(galleryimg.resize[selectImageType].img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.resize[selectImageType].img" alt="gallery" />
                    </div>
                    <div v-else @click="ArrayIndexObject(galleryimg.gallery_img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.gallery_img" alt="gallery" />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
            <b-tab @click="recent_image_used_in_gallery">
              <template #title>
                <span>Recently Used</span>
              </template>

              <div :key="sidebarkey">
                <b-row class="match-height">
                  <b-col md="1" lg="1" style="padding: 10px; position: relative" v-for="galleryimg in getImages()"
                    :key="galleryimg.img_id" v-lazy-container="{
                        selector: 'img',
                        error:
                          'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                        loading:
                          'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
                      }">
                     <div v-if="galleryimg.resize && galleryimg.resize[selectImageType]" @click="ArrayIndexObject(galleryimg.resize[selectImageType].img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.resize[selectImageType].img" alt="gallery" />
                    </div>
                    <div v-else @click="ArrayIndexObject(galleryimg.gallery_img)">
                      <img fluid class="mb-2" style="width: 100px; height: 100px; object-fit: contain"
                        :data-src="galleryimg.gallery_img" alt="gallery" />
                    </div>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </b-modal>
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        ">
        <h5 class="mb-0">Edit Question</h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)">
          <div class="text-center">
            <div v-if="revisionQuestion.revisionNo != 1 && revisionQuestion.revisionStatus == false"
              @click="showPendingRevision()" class="curser-pointer" style="font-weight: bold;
                font-size: 20px;
                color: red;
                cursor: pointer;">
              <strong> You have one pending revision.</strong>
            </div>
          </div>
          <validation-provider name="Question_Type" :rules="{ required: true, min: 3 }" v-slot="validationContext">
            <b-form-group id="example-input-group-3" label="Question Type" label-for="example-input-3">
              <b-form-select id="example-input-3" name="example-input-3" v-model="selected" :options="SelectType"
                v-on:change="checkType(selected)" aria-describedby="input-3-live-feedback"></b-form-select>

              <b-form-invalid-feedback id="input-3-live-feedback">{{
                validationContext.errors[0]
              }}</b-form-invalid-feedback>
            </b-form-group>

          </validation-provider>

          <div :key="sidebarkey">
            <span v-for="(formdata, index) in schemaData" :key="index + 'gsd'">
              <div v-if="formdata.show == true">
                <h4>{{ formdata.fieldname }}</h4>
              </div>

              <div v-if="formdata.fieldType == 'text'">
                <div v-if="formdata.show == true">
                  <b-form-input id="example-input-2" name="example-input-2" v-model="formdata.data"></b-form-input>
                </div>
              </div>
              <div v-if="formdata.fieldType == 'image'">
                <div v-if="formdata.show == true">
                  <span style="
                      border: 1px solid black;
                      width: 70px;
                      height: auto;
                      position: relative;
                    ">
                    <feather-icon class="ml-1 cursor-pointer" icon="XIcon" style="
                        background: red;
                        width: 17px;
                        height: 17px;
                        position: absolute;
                        right: 0;
                      " @click="removeImageFromJson(index)" />
                    <span v-if="formdata.data"><img style="width: 100px; height: 100px" :src="formdata.data" alt=""/></span>
                    <span v-else><img style="width: 100px; height: 100px"
                        src="https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg" alt=""/></span>
                  </span>
                  <br />
                  <br />
                  <b-button variant="primary" @click="showgalleryimage(index)" v-b-modal.modal-xl-edit>
                    <span class="d-none d-sm-inline">Gallery Images</span>
                  </b-button>
                  <b-button style="margin-left: 10px" variant="primary" @click="changeIndexOfImage(index)">
                    <input :ref="'ref_InputI' + index" type="file" accept="image/x-png,image/gif,image/jpeg"
                      class="d-none" @input="ImageRendererItems" />
                    <span class="d-none d-sm-inline">Upload</span>
                    <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                  </b-button>
                </div>
              </div>
              <div v-if="formdata.fieldType == 'backgroundImage'">
                <div v-if="formdata.show == true">
                  <span style="
                      width: 70px;
                      height: auto;
                      position: relative;
                    ">
                    <feather-icon class="ml-1 cursor-pointer" icon="XIcon" style="
                        background: red;
                        width: 17px;
                        height: 17px;
                        position: absolute;
                        right: 0;
                      " @click="removeImageFromJson(index)" />
                    <span v-if="formdata.data"><img style="width: 100px; height: 100px" :src="formdata.data" /></span>
                    <span v-else><img style="width: 100px; height: 100px"
                        src="https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg" alt=""/></span>
                  </span>
                  <br />
                  <br />
                  <b-button variant="primary" @click="showgallerybackground(index)" v-b-modal.modal-xl-edit>
                    <span class="d-none d-sm-inline">Gallery Images</span>
                  </b-button>
                  <b-button style="margin-left: 10px" variant="primary" @click="backgroundFunction(index)"
                    v-if="removebutton">
                    <input :ref="'ref_Input' + index" type="file" accept="image/x-png,image/gif,image/jpeg" class="d-none"
                      @input="BackgroundPathChange" />
                    <span class="d-none d-sm-inline">Upload</span>
                    <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                  </b-button>
                 </div>
              </div>
              <div v-if="formdata.fieldType == 'backgroundColor'">
                <div v-if="formdata.show == true">
                  <color-picker theme="light" :color="color" :sucker-hide="false" :sucker-canvas="suckerCanvas"
                    :sucker-area="suckerArea" @changeColor="changeColor" @openSucker="openSucker" />
                </div>
              </div>
              <div v-if="formdata.fieldType == 'dropdown'">
                <div v-if="formdata.show == true">
                  <b-form-select v-on:change="changebgtype(selected_bg_type, index)" v-model="selected_bg_type"
                    :options="formdata.values">
                  </b-form-select>
                </div>
                <div style="background: black; color: white">
                  {{ formdata.data }}
                </div>
              </div>
              <div v-if="formdata.fieldType == 'array'">
                <div v-if="formdata.show == true">
                  <span v-for="(nestedData, ind) in formdata.nested" :key="ind">
                    <!-- {{ nestedData.value }} -->
                    <div v-if="nestedData.value">
                      <div style="position: relative" v-if="nestedData.value.fieldType == 'text'">
                        <div v-if="nestedData.value.show == true">
                          <h6 style="margin-left: 10px">
                            {{ nestedData.value.fieldname + "_" + ind }}
                          </h6>
                          <b-form-input style="margin-left: 10px; width: 40%" id="example-input-2" name="example-input-2"
                            v-model="nestedData.value.data"></b-form-input>
                        </div>

                        <div v-if="nestedData.isCorrect.fieldType == 'boolean'" style="
                            margin-left: 10px;
                            position: absolute;
                            left: 254px;
                            bottom: 4px;
                          ">
                          <div style="
                              background: #7367f0;
                              padding: 5px;
                              border-radius: 5px;
                              font-weight: 700;
                            " v-if="formdata.show == true">
                            <b-form-checkbox v-model="nestedData.isCorrect.data" v-on:change="checkboxdata(index, ind)">
                              <span style="color: white">
                                {{ nestedData.isCorrect.data }}
                              </span>
                            </b-form-checkbox>
                          </div>
                        </div>
                      </div>

                      <div v-if="nestedData.value.fieldType == 'image'">
                        <div style="position: relative" v-if="nestedData.value.show == true">
                          <h6 style="margin-left: 10px">
                            {{ nestedData.value.fieldname + "_" + ind }}
                          </h6>
                          <span style="
                              /* border: 1px solid black; */
                              width: 70px;
                              height: auto;
                              position: relative;
                            ">
                            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" style="
                                background: red;
                                width: 17px;
                                height: 17px;
                                position: absolute;
                                right: 0;
                              " @click="removeImageFromJsonIsCorrect(index, ind)" />
                            <span v-if="nestedData.value.data"><img style="width: 100px; height: 100px"
                                :src="nestedData.value.data" alt="" /></span>
                            <span v-else><img style="width: 100px; height: 100px"
                                src="https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg" alt=""/></span>
                          </span>
                          <span v-if="nestedData.isCorrect.fieldType == 'boolean'">
                            <span style="
                                position: absolute;
                                top: 32%;
                                left: 25%;
                                background: #7367f0;
                                padding: 5px;
                                border-radius: 5px;
                                font-weight: 700;
                              " v-if="formdata.show == true">
                              <b-form-checkbox v-model="nestedData.isCorrect.data" v-on:change="checkboxdata(index, ind)">
                                <span style="color: white">
                                  {{ nestedData.isCorrect.data }}
                                </span>
                              </b-form-checkbox>
                            </span>
                          </span>
                          <br />
                          <br />

                          <b-button variant="primary" v-b-modal.modal-Arr-checkBox-edit
                            @click="showgalleryArray(index, ind)">
                            <span class="d-none d-sm-inline">Gallery Images</span>
                          </b-button>
                          <b-button style="margin-left: 10px" variant="primary" @click="ArrayConvert(index, ind)">
                            <input :ref="'ref_InputE' + ind" type="file" accept="image/x-png,image/gif,image/jpeg"
                              class="d-none" @input="NestedPathChange" />
                            <span class="d-none d-sm-inline">Upload</span>
                            <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                          </b-button>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div v-if="nestedData.fieldType == 'text'">
                        <div v-if="nestedData.show == true">
                          <h6 style="margin-left: 10px">
                            {{ nestedData.fieldname }}
                          </h6>
                          <b-form-input style="margin-left: 10px" id="example-input-2" name="example-input-2"
                            v-model="nestedData.data"></b-form-input>
                        </div>
                      </div>

                      <div v-if="nestedData.fieldType == 'image'">
                        <div style="position: relative" v-if="nestedData.show == true">
                          <h6 style="margin-left: 10px">
                            {{ nestedData.fieldname }}
                          </h6>
                          <span style="
                              width: 70px;
                              height: auto;
                              position: relative;
                            ">
                            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" style="
                                background: red;
                                width: 17px;
                                height: 17px;
                                position: absolute;
                                right: 0;
                              " @click="removeImageFromJsonNotIsCorrect(index, ind)
                                " />
                            <span v-if="nestedData.data"><img style="width: 100px; height: 100px"
                                :src="nestedData.data" alt=""/></span>
                            <span v-else><img style="width: 100px; height: 100px"
                                src="https://t4.ftcdn.net/jpg/02/07/87/79/360_F_207877921_BtG6ZKAVvtLyc5GWpBNEIlIxsffTtWkv.jpg" alt="" /></span>
                          </span>
                          <br />
                          <br />

                          <b-button variant="primary" @click="Gllerydatashow(index, ind)" v-b-modal.cl-image-edit>
                            <span class="d-none d-sm-inline">Gallery Images</span>
                          </b-button>
                          <b-button style="margin-left: 10px" variant="primary" @click="ArrayImagedata(index, ind)">
                            <input :ref="'ref_InputES' + ind" type="file" accept="image/x-png,image/gif,image/jpeg"
                              class="d-none" @input="NestedPathDataChnage" />
                            <span class="d-none d-sm-inline">Upload</span>
                            <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                          </b-button>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
              </div>
            </span>
          </div>
          <div>
            <div class="d-flex bg-light text-light align-items-center px-1 py-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" type="submit"
                :disabled="submitbutton">
                Submit
              </b-button>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" class="ml-2" type="button" variant="outline-secondary"
                @click="hide">
                Cancel
              </b-button>
            </div>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCol,
  BFormFile,
  BRow,
  BLink,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormSelect,
  BBadge,
  VBTooltip,
  BFormCheckbox,
  BIconTypeBold,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import { required, alphaNum, email } from "@validations";
import vSelect from "vue-select";
import axios from "axios";
import Vue from "vue";
import BootstrapVue from "bootstrap-vue";
import colorPicker from "@caohenghu/vue-colorpicker";
import S3 from "aws-s3";
import * as _ from "lodash";
import { BTabs, BTab, BCardText } from "bootstrap-vue";

export default {
  components: {
    BSidebar,
    BFormCheckbox,
    BCol,
    BRow,
    BLink,
    BImg,
    BForm,
    BBadge,
    BFormGroup,
    BTabs,
    BTab,
    BCardText,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormSelect,
    vSelect,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    colorPicker,
    VBTooltip,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  model: {
    prop: "isAddEditTypeSidebarActive",
    event: "update:is-add-edit-type-sidebar-active",
  },
  props: {
    isAddEditTypeSidebarActive: {
      type: Boolean,
      required: true,
    },
    editdata: {
      type: Object,
      required: true,
    },
  },
  watch: {
    isAddEditTypeSidebarActive(val) {
      this.selected = this.editdata.questionType;
      this.changetoschema(this.editdata);
      this.checkRevisionQuestion(this.editdata);
    },
  },
  data() {
    return {
      color: "#59c7f9",
      sidebarkey: 0,
      suckerCanvas: null,
      Recent_use: [],
      suckerArea: [],
      isSucking: false,
      reqQuestionData: {},
      SelectType: [],
      schemaData: [],
      selected: "",
      showImage: false,
      removebutton: true,
      selected_bg_type: "",
      searchQuery: "",
      table_data: [],
      table_: {},
      backgroundIndexImage: "",
      BgImage: "",
      checkBoxImageIndex: "",
      revisionQuestion: {},
      optionImageType: [
        {'value': 0 ,'text' :'100px'},
        {'value': 1 ,'text' :'300px'},
        {'value': 2 ,'text' :'500px'},
      ],
      selectImageType: 0,
    };
  },
  created() {
    this.get_question_type();
    this.recent_image_add_in_gallery();
  },
  computed: {
    submitbutton() {
      return !(this.schemaData.length > 0);
    },
  },
  methods: {
    changeOptionImageResize() {
      this.forcRender();
    },
    showPendingRevision() {
      this.changetoschema(this.revisionQuestion);
    },
    checkRevisionQuestion(data) {
      axios
        .get(process.env.VUE_APP_API_URL + `/check-revision/status/${data._id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((res) => {
          this.revisionQuestion = res.data.question;
        });
    },
    preImage() {
      if (this.pageNewData != 0) {
        this.table_data = [];
        for (let index = 50; index > 0; index--) {
          this.pageNewData = this.pageNewData - 1;
          this.table_data.push(this.table_[this.pageNewData]);
        }
      }
    },
    nextImage() {
      this.table_data = [];
      if (this.table_.length) {
        for (let index = 0; index < 50; index++) {
          this.pageNewData = this.pageNewData + 1;
          this.table_data.push(this.table_[this.pageNewData]);
        }
      }
    },
    ArrayIndexObject(checkboximage) {
      this.Recent_use.push(checkboximage);
      this.schemaData[this.backgroundIndexImage].nested[
        this.checkBoxImageIndex
      ].data = checkboximage;
      this.searchQuery = "";
      this.$root.$emit("bv::hide::modal", "cl-image-edit");
      this.forcRender();
    },
    ArrayCheckboxIndex(checkboximage) {
      this.Recent_use.push(checkboximage);
      this.schemaData[this.backgroundIndexImage].nested[
        this.checkBoxImageIndex
      ].value.data = checkboximage;
      this.searchQuery = "";
      this.$root.$emit("bv::hide::modal", "modal-Arr-checkBox-edit");
      this.forcRender();
    },
    addBackgroundModel() {
      this.Recent_use.push(checkboximage);
      for (let index = 0; index < this.schemaData.length; index++) {
        if (this.backgroundIndexImage == index) {
          this.schemaData[index].data = imgBg;
        }
      }
      this.BgImage = imgBg;
      // this.schemaData[arrIndex].showImage = false;
      this.searchQuery = "";
      this.$root.$emit("bv::hide::modal", "modal-xl");
      this.forcRender();
    },
    closeBackgroundModel() {
      this.$root.$emit("bv::hide::modal", "modal-xl-edit");
    },
    backgroundImageSave(imgBg) {
      this.Recent_use.push(imgBg);
      for (let index = 0; index < this.schemaData.length; index++) {
        if (this.backgroundIndexImage == index) {
          this.schemaData[index].data = imgBg;
        }
      }
      this.BgImage = imgBg;
      this.searchQuery = "";
      this.$root.$emit("bv::hide::modal", "modal-xl-edit");
      this.forcRender();
    },
    recent_image_used_in_gallery: function () {
      (this.searchQuery = ""), (this.table_data = []);
      (this.pageNewData = 0),
        (this.table_ = {}),
        axios
          .get(process.env.VUE_APP_API_URL + "/zape-cms/recent-used/images", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          })
          .then((get_response) => {
            this.table_ = get_response.data;
            for (let index = 0; index < 50; index++) {
              this.table_data.push(get_response.data[index]);
              this.pageNewData = this.pageNewData + 1;
              this.forcRender();
            }
          });
    },
    recent_image_add_in_gallery: function () {
      (this.searchQuery = ""), (this.table_data = []);
      (this.pageNewData = 0),
        (this.table_ = {}),
        axios
          .get(process.env.VUE_APP_API_URL + "/zape-cms/recent-add/images",
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            })
          .then((get_response) => {
            this.table_ = get_response.data;
            for (let index = 0; index < 50; index++) {
              this.table_data.push(get_response.data[index]);
              this.pageNewData = this.pageNewData + 1;
              this.forcRender();
            }
          });
    },
    hidegallery(i, j) {
      this.schemaData[i].nested[j].showImage = false;
      this.searchQuery = "";
      this.forcRender();
    },
    ArrayImagedata(schemain, nestedin) {
      this.optionindex = schemain;
      this.arrayIndexes = nestedin;
      var target = "ref_InputES" + nestedin;
      var x = this.$refs[target];
      x[0].click();
    },
    Gllerydatashow(i, j) {
      this.backgroundIndexImage = i;
      this.checkBoxImageIndex = j;
    },
    forcRender() {
      this.sidebarkey += 1;
    },
    changetoschema(objectdata) {
      objectdata = JSON.stringify(objectdata);
      objectdata = JSON.parse(objectdata.replace(/null/g, '""'));
      objectdata = JSON.stringify(objectdata);
      objectdata = JSON.parse(objectdata.replace(/url/g, "value"));
      for (let index = 0; index < this.reqQuestionData.length; index++) {
        if (
          this.reqQuestionData[index].structure.name ==
          this.editdata.questionType
        ) {
          this.schemaData = this.reqQuestionData[index].schema;
        }
      }
      let createObject = {
        fieldname: "question_id",
        fieldType: "text",
        show: false,
        values: [],
        nested: [],
        data: objectdata["_id"],
      };
      this.schemaData.push(createObject);
      this.schemaData = JSON.stringify(this.schemaData);
      this.schemaData = JSON.parse(this.schemaData.replace(/url/g, "value"));
      for (let index = 0; index < this.schemaData.length; index++) {
        var objectKeyArr = Object.keys(objectdata);
        for (let i = 0; i < objectKeyArr.length; i++) {
          if (this.schemaData[index].fieldname == objectKeyArr[i]) {
            if (this.schemaData[index].fieldType == "text") {
              this.schemaData[index].data = objectdata[objectKeyArr[i]];
            }
            if (this.schemaData[index].fieldType == "image") {
              this.schemaData[index].data = objectdata[objectKeyArr[i]];
            }
            if (this.schemaData[index].fieldType == "dropdown") {
              if (objectKeyArr[i] == "backgroundType") {
                if (objectdata[objectKeyArr[i]] == "image") {
                  this.schemaData[index].data = "backgroundImage";
                } else if (objectdata[objectKeyArr[i]] == "color") {
                  this.schemaData[index].data = "backgroundColor";
                }
              } else {
                this.schemaData[index].data = objectdata[objectKeyArr[i]];
              }
            }
            if (this.schemaData[index].fieldType == "array") {
              if (typeof objectdata[objectKeyArr[i]][0] == "string") {
                for (let j = 0; j < this.schemaData[index].nested.length; j++) {
                  this.schemaData[index].nested[j].data =
                    objectdata[objectKeyArr[i]][j];
                }
              }
              if (typeof objectdata[objectKeyArr[i]][0] == "object") {
                for (let j = 0; j < this.schemaData[index].nested.length; j++) {
                  this.schemaData[index].nested[j].value.data =
                    objectdata[objectKeyArr[i]][j].value;
                  this.schemaData[index].nested[j].isCorrect.data =
                    objectdata[objectKeyArr[i]][j].isCorrect;
                }
              }
            }
          }
        }
        if (this.schemaData[index].fieldType == "backgroundImage") {
          this.schemaData[index].data = objectdata["background"];
        }
        if (this.schemaData[index].fieldType == "backgroundColor") {
          this.schemaData[index].data = objectdata["background"];
        }
      }
      for (let l = 0; l < this.schemaData.length; l++) {
        if (this.schemaData[l].fieldname == "name") {
          this.schemaData.splice(l, 1);
        }
      }
    },
    checkType(typeName) {
      for (let index = 0; index < this.reqQuestionData.length; index++) {
        if (this.reqQuestionData[index].structure.name == typeName) {
          this.schemaData = this.reqQuestionData[index].schema;
        }
      }
    },
    get_question_type: function () {
      axios
        .get(process.env.VUE_APP_API_URL + "/GET_ALL_QUESTION_TYPE_FORM", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          this.reqQuestionData = get_response.data.questionType;
          for (let index = 0; index < this.reqQuestionData.length; index++) {
            this.SelectType.push(this.reqQuestionData[index].structure.name);
          }
        });
    },

    ArrayConvert(schemain, nestedin) {
      this.optionindex = schemain;
      this.arrayIndexes = nestedin;
      var target = "ref_InputE" + nestedin;
      var x = this.$refs[target];
      x[0].click();
    },

    changeInd(schemaindex, nestedindex) {
      this.optionindex = schemaindex;
      this.arrayIndexes = nestedindex;
      var target = "ref_Input" + nestedindex;
      var x = this.$refs[target];
      x[0].click();
    },
    changeIndexOfImage(e) {
      this.optionindex = e;
      var target = "ref_InputI" + e;
      var x = this.$refs[target];
      x[0].click();
    },
    backgroundFunction(e) {
      this.optionindex = e;
      var target = "ref_Input" + e;
      var x = this.$refs[target];
      x[0].click();
    },
    gettabledata: function () {
      axios
        .get(process.env.VUE_APP_API_URL + "/zape-cms/gallery", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          // console.log(response.data);
          this.table_data = get_response.data;
        });
    },
    getImages() {
      if (this.searchQuery.trim().length > 0) {
        return this.table_.filter((item) => {
          // console.log(item);
          if (item.image_name) {
            return this.searchQuery
              .toLowerCase()
              .split(" ")
              .every((v) => item.image_name.toLowerCase().includes(v));
          }
        });
      } else {
        return this.table_data;
      }
    },
    hideimagegallery(i) {
      this.schemaData[i].showImage = false;
      this.searchQuery = "";
      this.forcRender();
    },
    showgalleryimage(i) {
      this.backgroundIndexImage = i;
    },

    gallerydataImg(data, i, j) {
      this.schemaData[i].nested[j].data = data;
      this.schemaData[i].nested[j].showImage = false;
      this.searchQuery = "";
      this.forcRender();
    },
    galleryimg(data, arrIndex) {
      for (let index = 0; index < this.schemaData.length; index++) {
        if (index == arrIndex) {
          this.schemaData[index].data = data;
        }
      }
      this.schemaData[arrIndex].showImage = false;
      this.searchQuery = "";
      this.forcRender();
    },

    changeColor(color) {
      const { r, g, b, a } = color.rgba;
      for (let index = 0; index < this.schemaData.length; index++) {
        if (this.schemaData[index].fieldType == "backgroundColor") {
          this.schemaData[index].data = color.hex;
        }
      }
      this.color = `rgba(${r}, ${g}, ${b}, ${a})`;
    },
    openSucker(isOpen) {
      if (isOpen) {
      } else {
      }
    },
    changebgtype(selectedType, arrIndex) {
      for (let index = 0; index < this.schemaData.length; index++) {
        if (index == arrIndex) {
          this.schemaData[index].data = selectedType;
        }
      }
      this.selected_bg_type = "";
    },
    checkType(typeName) {
      for (let index = 0; index < this.reqQuestionData.length; index++) {
        if (this.reqQuestionData[index].structure.name == typeName) {
          this.schemaData = this.reqQuestionData[index].schema;
        }
      }
    },
    checkquestiontype(a) {
      this.arrFormField = [];
      var newfield = {};

      for (let index = 0; index < this.reqQuestionData.length; index++) {
        if (this.reqQuestionData[index].schema.name == a) {
          this.data = this.reqQuestionData[index].schema;
          var oldKey = "";
          for (let [key, value] of Object.entries(this.data)) {
            var currentdata = {};
            currentdata = { key: oldKey, schema: value, value: null };
            if (oldKey.includes("FieldSchema")) {
              oldKey = "";
            }
            oldKey = key;
            if (key.includes("FieldSchema")) {
              this.arrFormField.push(currentdata);
            }
          }
          this.copyFormField = JSON.parse(JSON.stringify(this.arrFormField));
        }
      }
      this.showform = true;
    },
    resetForm() { },
    onSubmit() {
      var schemaFinalObject = {};
      var nestedfinalobject = {};
      var nestedfincalarr = [];
      var urlData = [];
      var iscorrects = [];
      var Objectcreatedarray = [];
      // this.questiondata.level_id = this.$route.params.levelId;
      schemaFinalObject.level_id = this.$route.params.levelId;
      schemaFinalObject.Recent_used_images = this.Recent_use;
      for (let index = 0; index < this.schemaData.length; index++) {
        for (let [key, value] of Object.entries(this.schemaData[index])) {
          if (key == "fieldname") {
            if (this.schemaData[index].fieldType == "text") {
              schemaFinalObject[value] = this.schemaData[index].data;
            }
            if (this.schemaData[index].fieldType == "image") {
              schemaFinalObject[value] = this.schemaData[index].data;
            }
            if (this.schemaData[index].fieldType == "backgroundColor") {
              if (this.schemaData[index].data != null) {
                schemaFinalObject[value] = this.schemaData[index].data;
              } else {
                schemaFinalObject[value] = "demo";
              }
            }
            if (this.schemaData[index].fieldType == "dropdown") {
              schemaFinalObject[value] = this.schemaData[index].data;
            }
            if (this.schemaData[index].fieldType == "backgroundImage") {
              if (this.schemaData[index].data != null) {
                schemaFinalObject[value] = this.schemaData[index].data;
              } else {
                schemaFinalObject[value] = "demo";
              }
            }
            if (this.schemaData[index].fieldType == "array") {
              nestedfincalarr = [];
              // console.log(this.schemaData[index].nested);
              Objectcreatedarray = [];
              urlData = [];
              iscorrects = [];
              for (let i = 0; i < this.schemaData[index].nested.length; i++) {
                // console.log(this.schemaData[index].nested[i].url);
                // Objectcreatedarray = [];
                if (this.schemaData[index].nested[i]) {
                  for (let [nestedkey, nestedvalue] of Object.entries(
                    this.schemaData[index].nested[i]
                  )) {
                    if (nestedkey == "fieldname") {
                      urlData = [];
                      // Objectcreatedarray = [];
                      iscorrects = [];
                      // console.log("urlxxx",this.schemaData[index].nested[i].url.data);
                      // console.log('keys',this.schemaData[index].nested[i].url.data);
                      // urlData.push(this.schemaData[index].nested[i].data);
                      Objectcreatedarray.push(
                        this.schemaData[index].nested[i].data
                      );
                    }
                  }
                }
                if (this.schemaData[index].nested[i].value) {
                  for (let [nestedkey, nestedvalue] of Object.entries(
                    this.schemaData[index].nested[i].value
                  )) {
                    if (nestedkey == "fieldname") {
                      Objectcreatedarray = [];
                      urlData.push(this.schemaData[index].nested[i].value.data);
                    }
                  }
                }
                if (this.schemaData[index].nested[i].isCorrect) {
                  for (let [nestedkey, nestedvalue] of Object.entries(
                    this.schemaData[index].nested[i].isCorrect
                  )) {
                    if (nestedkey == "fieldname") {
                      Objectcreatedarray = [];
                      iscorrects.push(
                        this.schemaData[index].nested[i].isCorrect.data
                      );
                    }
                  }
                }
              }
              var newobject = {};
              for (let j = 0; j < urlData.length; j++) {
                newobject = {};
                newobject["value"] = urlData[j];
                newobject["isCorrect"] = iscorrects[j];
                Objectcreatedarray.push(newobject);
              }
              schemaFinalObject[value] = Objectcreatedarray;
            }
          }
        }
      }

      // console.log(schemaFinalObject);
      this.$emit("ClickEditQuestiondata", schemaFinalObject);
      this.selected = "";
      this.schemaData = {};
      this.selected_bg_type = "";
      this.$root.$emit("bv::toggle::collapse", "add-edit-type-sidebar");
    },
    BackgroundPathChange(data) {
      // console.log("in background");
      // console.log(this.optionindex);
      if (data.target.files && data.target.files[0]) {
        const config = {
          bucketName: "zape02",
          region: "ap-southeast-1",
          accessKeyId: "AKIA2ANCMLDFN2FQFVLZ",
          secretAccessKey: "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",
          s3Url: "https://zape02.s3.ap-southeast-1.amazonaws.com",
          visibility: "public",
        };
        const S3Client = new S3(config);
        const image =
          Math.random().toString() +
          Math.random().toString() +
          Math.random().toString();
        // var a = "";
        S3Client.uploadFile(data.target.files[0], image)
          .then((data) => {
            for (let index = 0; index < this.schemaData.length; index++) {
              if (index == this.optionindex) {
                this.schemaData[index].data = data.location;
              }
            }
          })
          .catch((err) => console.error(err));
      }
    },
    ImageRendererItems(data) {
      // console.log("in images");
      // console.log(this.optionindex);
      if (data.target.files && data.target.files[0]) {
        const config = {
          bucketName: "zape02",
          region: "ap-southeast-1",
          accessKeyId: "AKIA2ANCMLDFN2FQFVLZ",
          secretAccessKey: "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",
          s3Url: "https://zape02.s3.ap-southeast-1.amazonaws.com",
          visibility: "public",
        };
        const S3Client = new S3(config);
        const image =
          Math.random().toString() +
          Math.random().toString() +
          Math.random().toString();
        // var a = "";
        S3Client.uploadFile(data.target.files[0], image)
          .then((data) => {
            for (let index = 0; index < this.schemaData.length; index++) {
              if (index == this.optionindex) {
                this.schemaData[index].data = data.location;
                this.Recent_use.push(data.location);
              }
            }
          })
          .catch((err) => console.error(err));
        // const reader = new FileReader();
        // reader.onload = (e) => {
        //   const a = [];
        //   a.push(e.target.result);
        //   axios
        //     .post(process.env.VUE_APP_API_URL + "/basetourl", a)
        //     .then((response) => {
        //       for (let index = 0; index < this.schemaData.length; index++) {
        //         if (index == this.optionindex) {
        //           this.schemaData[index].data = response.data;
        //         }
        //       }
        //     });
        // };
        // reader.readAsDataURL(data.target.files[0]);
      }
    },
    removeImageFromJson(i) {
      this.schemaData[i].data = "";
    },
    removeImageFromJsonIsCorrect(i, j) {
      this.schemaData[i].nested[j].value.data = "";
    },
    removeImageFromJsonNotIsCorrect(i, j) {
      this.schemaData[i].nested[j].data = "";
    },
    showgallerybackground(i) {
      this.backgroundIndexImage = i;
      // this.schemaData[i].showImage = true;
      // this.forcRender();
    },
    hidebackgroundgallery(i) {
      this.schemaData[i].showImage = false;
      this.searchQuery = "";
      this.forcRender();
    },
    showgalleryArray(i, j) {
      this.backgroundIndexImage = i;
      this.checkBoxImageIndex = j;
      // alert(0)
      // console.log(this.schemaData[i]);
      // this.$set(this.schemaData[i],"showImage" , true)
      // console.log(this.schemaData[i].nested[j].url)
      // this.schemaData[i].nested[j].value.showImage = true;
      // this.forcRender();
      // console.log(this.schemaData[i].nested[j].url.showImage);
    },
    hideArraygallery(i, j) {
      // alert(0)
      this.schemaData[i].nested[j].value.showImage = false;
      this.searchQuery = "";
      this.forcRender();
    },
    galleryImgArray(data, i, j) {
      // alert(0)
      // console.log("old data", this.schemaData[i].nested[j]);
      this.schemaData[i].nested[j].value.data = data;

      this.schemaData[i].nested[j].value.showImage = false;
      // console.log("olddata",this.schemaData[i].nested[j] )
      this.searchQuery = "";
      this.forcRender();
    },
    checkboxdata(i, j) {
      // alert(0)
      //  console.log(this.schemaData[i].showImage);
      // this.$set(this.schemaData[i],"showImage" , true)
      // console.log(this.schemaData[i].nested[j].url)
      this.schemaData[i].nested[j].isCorrect.showboolean = true;
      // console.log(this.schemaData[i].nested[j].isCorrect)
      this.forcRender();
      // console.log(this.schemaData[i].nested[j].url.showImage);
    },
    NestedPathDataChnage(data) {
      // console.log("in nested");
      // console.log(this.optionindex);
      if (data.target.files && data.target.files[0]) {
        const config = {
          bucketName: "zape02",
          region: "ap-southeast-1",
          accessKeyId: "AKIA2ANCMLDFN2FQFVLZ",
          secretAccessKey: "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",
          s3Url: "https://zape02.s3.ap-southeast-1.amazonaws.com",
          visibility: "public",
        };
        const S3Client = new S3(config);
        const image =
          Math.random().toString() +
          Math.random().toString() +
          Math.random().toString();
        // var a = "";
        S3Client.uploadFile(data.target.files[0], image)
          .then((data) => {
            for (let index = 0; index < this.schemaData.length; index++) {
              if (index == this.optionindex) {
                var nest = this.schemaData[index].nested;
                for (let ind = 0; ind < nest.length; ind++) {
                  if (ind == this.arrayIndexes) {
                    this.schemaData[index].nested[ind].data = data.location;
                    this.Recent_use.push(data.location);
                  }
                  // const element = array[index];
                }
                // this.schemaData[index].data = response.data;
              }
            }
          })
          .catch((err) => console.error(err));
        // const reader = new FileReader();
        // reader.onload = (e) => {
        //   const a = [];
        //   a.push(e.target.result);
        //   axios
        //     .post(process.env.VUE_APP_API_URL + "/basetourl", a)
        //     .then((response) => {
        //       for (let index = 0; index < this.schemaData.length; index++) {
        //         if (index == this.optionindex) {
        //           var nest = this.schemaData[index].nested;
        //           for (let ind = 0; ind < nest.length; ind++) {
        //             if (ind == this.arrayIndexes) {
        //               this.schemaData[index].nested[ind].data = response.data;
        //             }
        //             // const element = array[index];
        //           }
        //           // this.schemaData[index].data = response.data;
        //         }
        //       }
        //       // console.log(this.schemaData);
        //     });
        // };
        // reader.readAsDataURL(data.target.files[0]);
      }
    },
    NestedPathChange(data) {
      // console.log("in nested");
      // console.log(this.optionindex);
      if (data.target.files && data.target.files[0]) {
        const config = {
          bucketName: "zape02",
          region: "ap-southeast-1",
          accessKeyId: "AKIA2ANCMLDFN2FQFVLZ",
          secretAccessKey: "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",
          s3Url: "https://zape02.s3.ap-southeast-1.amazonaws.com",
          visibility: "public",
        };
        const S3Client = new S3(config);
        const image =
          Math.random().toString() +
          Math.random().toString() +
          Math.random().toString();
        // var a = "";
        S3Client.uploadFile(data.target.files[0], image)
          .then((data) => {
            for (let index = 0; index < this.schemaData.length; index++) {
              if (index == this.optionindex) {
                var nest = this.schemaData[index].nested;
                for (let ind = 0; ind < nest.length; ind++) {
                  if (ind == this.arrayIndexes) {
                    this.schemaData[index].nested[ind].data = data.location;
                    this.Recent_use.push(data.location);
                    // console.log(this.schemaData[index].;
                  }
                  // const element = array[index];
                }
                // this.schemaData[index].data = response.data;
              }
            }
          })
          .catch((err) => console.error(err));
        // const reader = new FileReader();
        // reader.onload = (e) => {
        //   const a = [];
        //   a.push(e.target.result);
        //   axios
        //     .post(process.env.VUE_APP_API_URL + "/basetourl", a)
        //     .then((response) => {
        //       for (let index = 0; index < this.schemaData.length; index++) {
        //         if (index == this.optionindex) {
        //           var nest = this.schemaData[index].nested;
        //           for (let ind = 0; ind < nest.length; ind++) {
        //             if (ind == this.arrayIndexes) {
        //               this.schemaData[index].nested[ind].data = response.data;
        //             }
        //             // const element = array[index];
        //           }
        //           // this.schemaData[index].data = response.data;
        //         }
        //       }
        //       // console.log(this.schemaData);
        //     });
        // };
        // reader.readAsDataURL(data.target.files[0]);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
